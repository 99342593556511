import {UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {isEmpty} from 'lodash-es';

export const maxlengthValidator = (maxlength: number, ...controlNames: string[]) => {
  return (form: UntypedFormGroup): ValidationErrors | null => {
    const values = controlNames.map(controlName => form.value[controlName]);
    let result: number = 0;

    values.forEach(value => {
      result += value?.length || 0;
    });

    if (result > maxlength) {
      controlNames.forEach(controlName => {
        form.controls[controlName].setErrors({maxlengthControls: true});
      });
    }

    if (result <= maxlength) {
      controlNames.forEach(controlName => {
        let errors = form.controls[controlName].errors;
        delete errors?.['maxlengthControls'];
        errors = isEmpty(errors) ? null : errors;

        form.controls[controlName].setErrors(errors);
      });
    }

    return null;
  };
};
