import {APP_INITIALIZER, LOCALE_ID, ErrorHandler, NgModule, NO_ERRORS_SCHEMA, Injector} from '@angular/core';

import {BrowserModule} from '@angular/platform-browser';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {provideFirebaseApp, initializeApp} from '@angular/fire/app';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogService} from 'primeng/dynamicdialog';
import {registerLocaleData} from '@angular/common';
import {BlockUIModule} from 'primeng/blockui';
import {IntercomModule} from 'ng-intercom';
import {ToastModule} from 'primeng/toast';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CookieService} from 'ngx-cookie-service';
import {add} from 'date-fns';

import {lastValueFrom} from 'rxjs';

import {captureException} from '@sentry/angular';

import {ENVIRONMENT_TOKEN, LOCALE_COOKIE_KEY, MENU_ITEMS_TOKEN} from '@wallex-core-client/core/constants/main-constants';
import {DEFAULT_LOCALE, Locale, LOCALES, ngLocales} from '@wallex-core/constants/language.constants';
import {UnauthorizedInterceptor} from '@wallex-core/interceptors/unauthorized.interceptor';
import {AuthTokenInterceptor} from '@wallex-core/interceptors/auth-token.interceptor';
import {DashboardModule} from '@wallex-core/features/dashboard/dashboard.module';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {LoaderInterceptor} from '@wallex-core/interceptors/loader.interceptor';
import {emptyAppConfig} from '@wallex-core/store/app/app-state.model';
import {MENU_ITEMS} from '~/app/constants/menu-items.constants';
import {AppService} from '@wallex-core/services/app.service';
import {StateModule} from '@wallex-core/store/state.module';
import {environment} from '~/environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {getCookie} from '@wallex-core-client/utils';
import {AppComponent} from './app.component';

export class MyErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    console.log('=== ERROR ===');
    console.log(error.message);
    console.log(error.stack);
    console.log('-------------');
    if (environment.sentryDsn) {
      captureException(error);
    }
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  const url = `${environment.serverBaseUri}/localization/web/`;
  return new TranslateHttpLoader(http, url, '/translations');
}

export function AppInitializerFactory(injector: Injector) {
  return async () => {
    const translate = injector.get(TranslateService);
    const appService = injector.get(AppService);
    const cookieService = injector.get(CookieService);
    const appStateFacade = injector.get(AppStateFacade);

    let appConfig;
    let currentLocale = cookieService.get(LOCALE_COOKIE_KEY);

    try {
      appConfig = await lastValueFrom(appService.getAppConfiguration());
    } catch {
      appConfig = emptyAppConfig;
    }

    const locales = appConfig.locales.map(({code}) => code);
    appStateFacade.config$.next(appConfig);

    if (!currentLocale) {
      const nextYear = add(new Date(), {years: 1});
      currentLocale = DEFAULT_LOCALE;
      cookieService.set(LOCALE_COOKIE_KEY, currentLocale, {expires: nextYear, path: '/'});
    }

    translate.setDefaultLang(DEFAULT_LOCALE);
    translate.addLangs(locales?.length ? locales : LOCALES);
    return translate.use(currentLocale).toPromise();
  };
}

const locale = (getCookie(LOCALE_COOKIE_KEY) || DEFAULT_LOCALE) as Locale;
const ngLocale = ngLocales[locale] || ngLocales[Locale.EN];

registerLocaleData(ngLocale);

// we need register FR locale to for decimal pipe
registerLocaleData(ngLocales[Locale.FR]);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    StateModule,
    BlockUIModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    ConfirmDialogModule,
    ToastModule,
    NgxSkeletonLoaderModule.forRoot({
      animation: 'progress'
    }),
    IntercomModule.forRoot({
      appId: environment.intercomId,
      updateOnRouterChange: false
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideMessaging(() => getMessaging()),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {provide: ErrorHandler, useClass: MyErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: ENVIRONMENT_TOKEN, useValue: environment},
    {provide: MENU_ITEMS_TOKEN, useValue: MENU_ITEMS},
    {provide: LOCALE_ID, useValue: ngLocale[0]},
    {provide: APP_INITIALIZER, useFactory: AppInitializerFactory, deps: [Injector], multi: true},
    DialogService
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
