import {ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef} from '@angular/core';

import {firstDateOfMonth, isSameMonthAndYear, previousMonth} from '@wallex-core-client/utils';
import {PAGINATION_TAKE} from '../../../../../core/constants/main-constants';

@Component({
  selector: 'app-infinite-scroll-by-date',
  templateUrl: './infinite-scroll-by-date.component.html',
  styleUrls: ['./infinite-scroll-by-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteScrollByDateComponent implements OnInit {
  @ContentChild('body', {static: false}) bodyTemplateRef!: TemplateRef<any>;
  @Input() containerHeight!: string;
  @Input() stopDate!: string;
  @Input() data!: any[];
  @Input() take: number = PAGINATION_TAKE;
  @Input() currentDatePage: string = firstDateOfMonth(new Date());
  @Output() loadNext = new EventEmitter<string>();

  private readonly threshold: number = 30;

  constructor(private readonly elRef: ElementRef) {}

  ngOnInit(): void {
    this.setContainerHeight();
  }

  @HostListener('scroll', []) onHostScroll() {
    const {scrollTop, scrollHeight, clientHeight} = this.elRef.nativeElement;
    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);

    if (distanceFromBottom > this.threshold) return;

    this.emitLoad();
  }

  public emitLoad(): void {
    if (isSameMonthAndYear(this.currentDatePage, this.stopDate)) {
      return;
    }

    this.currentDatePage = previousMonth(this.currentDatePage);
    this.loadNext.emit(this.currentDatePage);
  }

  private setContainerHeight(): void {
    this.elRef.nativeElement.style.height = this.containerHeight;
  }
}
