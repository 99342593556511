import {Pipe, PipeTransform} from '@angular/core';
import {isNaN} from 'lodash-es';

@Pipe({
  name: 'plusNumbers'
})
export class PlusNumbersPipe implements PipeTransform {
  transform(firstValue: string | number | null, secondValue: string | number | null): string {
    const firstNumber = Number(firstValue);
    const secondNumber = Number(secondValue);

    if (isNaN(firstNumber) || isNaN(secondNumber)) return '0';

    return String(firstNumber + secondNumber);
  }
}
