import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {InfiniteScrollByDateComponent} from '@wallex-core-client/ui';

@NgModule({
  declarations: [InfiniteScrollByDateComponent],
  imports: [CommonModule],
  exports: [InfiniteScrollByDateComponent]
})
export class InfiniteScrollByDateModule {}
