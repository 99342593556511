import {Action} from '@ngrx/store';

import {IAutoConversionStatus} from '@wallex-core-client/core/interfaces/auto-conversion.interface';
import {IGeneralSettings} from '@wallex-core-client/core/interfaces/general-settings.interface';
import {RefCurrencyResponseDto} from '@wallex-core-client/core/dtos/reference-currency.dto';
import {IAppCountries} from '@wallex-core-client/core/interfaces/country.interface';
import {IAsset} from '@wallex-core-client/core/interfaces/asset.interface';

export enum SettingsActionType {
  LOAD_COUNTRIES = '[Settings] Load countries',
  LOAD_COUNTRIES_FAILURE = '[Settings] Load Countries Failure',
  ADD_COUNTRIES = '[Settings] Add countries',
  ADD_CURRENCIES = '[Settings] Add currencies',
  LOAD_GENERAL_SETTINGS = '[Settings] Load Auto Conversion and Balance settings',
  LOAD_GENERAL_SETTINGS_FAILURE = '[Settings] Load Auto Conversion and Balance settings Failure',
  UPDATE_AUTO_CONVERSION_STATUS = '[Settings] Update Auto Conversion Status',
  UPDATE_GENERAL_SETTINGS = '[Settings] Update General Settings',
  LOAD_AUTO_CONVERSION_CURRENCIES = '[Settings] Load Auto Conversion Currencies',
  LOAD_AUTO_CONVERSION_CURRENCIES_FAILURE = '[Settings] Load Auto Conversion Currencies Failure',
  UPDATE_AUTO_CONVERSION_CURRENCIES = '[Settings] Update Auto Conversion Currencies',
  UPDATE_BALANCE_VISIBILITY = '[Settings] Update balance visibility',
  CLEAR_SETTINGS = '[Settings] Clear Settings'
}

export class LoadCountries implements Action {
  readonly type = SettingsActionType.LOAD_COUNTRIES;

  constructor() {}
}

export class AddCountries implements Action {
  readonly type = SettingsActionType.ADD_COUNTRIES;

  constructor(readonly appCountries: IAppCountries) {}
}

export class LoadCountriesFailure implements Action {
  readonly type = SettingsActionType.LOAD_COUNTRIES_FAILURE;

  constructor() {}
}

export class AddCurrencies implements Action {
  readonly type = SettingsActionType.ADD_CURRENCIES;

  constructor(readonly currencies: RefCurrencyResponseDto[]) {}
}

export class LoadGeneralSettings implements Action {
  readonly type = SettingsActionType.LOAD_GENERAL_SETTINGS;

  constructor() {}
}

export class UpdateGeneralSettings implements Action {
  readonly type = SettingsActionType.UPDATE_GENERAL_SETTINGS;

  constructor(readonly settings: IGeneralSettings) {}
}

export class UpdateAutoConversionStatus implements Action {
  readonly type = SettingsActionType.UPDATE_AUTO_CONVERSION_STATUS;

  constructor(readonly autoConversionStatus: IAutoConversionStatus) {}
}

export class LoadGeneralSettingsFailure implements Action {
  readonly type = SettingsActionType.LOAD_GENERAL_SETTINGS_FAILURE;

  constructor() {}
}

export class LoadAutoConversionCurrencies implements Action {
  readonly type = SettingsActionType.LOAD_AUTO_CONVERSION_CURRENCIES;

  constructor() {}
}

export class UpdateAutoConversionCurrencies implements Action {
  readonly type = SettingsActionType.UPDATE_AUTO_CONVERSION_CURRENCIES;

  constructor(readonly autoConversionCurrencies: IAsset[]) {}
}

export class LoadAutoConversionCurrenciesFailure implements Action {
  readonly type = SettingsActionType.LOAD_AUTO_CONVERSION_CURRENCIES_FAILURE;
  constructor() {}
}

export class UpdateBalanceVisibility implements Action {
  readonly type = SettingsActionType.UPDATE_BALANCE_VISIBILITY;

  constructor(readonly isBalanceSeen: boolean) {}
}

export class ClearSettings implements Action {
  readonly type = SettingsActionType.CLEAR_SETTINGS;

  constructor() {}
}

export type SettingsAction =
  | LoadCountries
  | AddCountries
  | AddCurrencies
  | LoadGeneralSettings
  | UpdateGeneralSettings
  | LoadAutoConversionCurrencies
  | UpdateAutoConversionCurrencies
  | UpdateAutoConversionStatus
  | LoadAutoConversionCurrenciesFailure
  | LoadGeneralSettingsFailure
  | LoadCountriesFailure
  | UpdateBalanceVisibility
  | ClearSettings;
